import React, { useState, useMemo } from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CButton,
  CSidebar,
  CSidebarNav,
  CNavItem,
  CNavLink,
} from '@coreui/react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import CIcon from '@coreui/icons-react';
import { cilX, cilOptions } from '@coreui/icons';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import Button, { Types } from '../../buttons/Button';
import OrderLineItem from '../../cards/OrderLineItem';
import {
  COUPON_TYPES,
  PAYMENT_METHODS,
  RECEIPT_TYPE,
  TAX_BEHAVIOUR,
  FULFILLMENT_STATUSES,
  ORDER_METHODS, DeliveryProvider,
} from '../../../services/exports/Constants';
import CreditCardIcon from '../../../resources/assets/icons/CreditCardIcon';
import PrinterIcon from '../../../resources/assets/icons/PrinterIcon';
import RejectOrderModal from '../RejectOrderModal';
import useFeedbackHandler from '../../../hooks/useFeedbackHandler';
import FeedbackLabel from '../../form/FeedbackLabel';
import StarIcon from '../../../resources/assets/icons/StarIcon';
import usePrinter from '../../../hooks/usePrinter';
import CashIcon from '../../../resources/assets/icons/CashIcon';
import useHelpers from '../../../hooks/useHelpers';
import RefundIcon from '../../../resources/assets/icons/RefundIcon';
import CreateRefundModal from '../CreateRefundModal';
import Footer from './footers/Footer';
import CarrierIcon from '../../../resources/assets/icons/CarrierIcon';
import PhoneIcon from '../../../resources/assets/icons/PhoneIcon';
import DeliveryTimeDifferenceBadge from '../../order/DeliveryTimeDifferenceBadge';
import PrimaryOrderBadge from '../../badge/PrimaryOrderBadge';
import OrderMethodIcon from '../../order/OrderMethodIcon';

export default function OrderDetailsModal({
  show = false,
  toggleModal,
  order: _order,
  refreshInfo,
  onOrderAccepted,
  onPickupTimeUpdated,
  onOrderPreparing,
  onOrderRefunded,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:OrderDetails:OrderDetailsModal' });

  const company = useSelector((state) => state.company?.company);
  const { orders } = useSelector((state) => state.orders);
  const order = collect(orders).firstWhere('id', _order.id) ?? _order;
  const { payment } = order;

  const {
    print, setPrinting, printing,
  } = usePrinter();
  const { formatCurrency, formatTime, formatDateTime } = useHelpers();

  const [showRejectOrderModal, setShowRejectOrderModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const { setFeedback } = useFeedbackHandler({
    renderToasts: true,
  });

  const toggleRejectOrderModal = () => setShowRejectOrderModal(!showRejectOrderModal);
  const toggleRefundModal = () => setShowRefundModal((current) => !current);

  const [showOptionsSideBar, setShowOptionsSidebar] = useState(false);

  const renderRejectOrderModal = useMemo(() => showRejectOrderModal && (
    <RejectOrderModal
      toggle={toggleRejectOrderModal}
      order={order}
      onReject={(order) => {
        onOrderRefunded && onOrderRefunded(order);
        toggleModal();
      }}
    />
  ), [showRejectOrderModal, order]);

  const renderRefundModal = useMemo(() => showRefundModal && (
    <CreateRefundModal
      payment={payment}
      toggle={toggleRefundModal}
      onRefund={onRefund}
    />
  ), [payment, showRefundModal]);

  if (!show || !order) {
    return null;
  }

  const isRejectable = order.fulfillment_status === FULFILLMENT_STATUSES.created
    || order.fulfillment_status === FULFILLMENT_STATUSES.reviewed
    || order.fulfillment_status === FULFILLMENT_STATUSES.accepted
    || order.fulfillment_status === FULFILLMENT_STATUSES.preparing
    || order.fulfillment_status === FULFILLMENT_STATUSES.ready
    || order.fulfillment_status === FULFILLMENT_STATUSES.on_delivery;

  const isRefundable = order.fulfillment_status !== FULFILLMENT_STATUSES.rejected
    && payment
    && payment.total_amount - collect(payment.refunds).sum('amount') !== 0;

  async function acceptOrder(data) {
    onOrderAccepted && await onOrderAccepted(data);

    if (data.is_pickup_time_optimized && company?.auto_print) {
      setPrinting(true);
      print({ type: RECEIPT_TYPE.ORDER, payload: data }, true);
    }

    toggleModal();
  }

  function onRefund() {
    refreshInfo();

    toast((
      <div className="d-flex">
        <RefundIcon className="mr-2" />
        <div className="website-text">
          <Trans
            t={t}
            values={{
              orderNumber: order.number,
            }}
          >
            toasts.refund_successful
          </Trans>
        </div>
      </div>
    ));
  }

  function updatePickupTime(data) {
    onPickupTimeUpdated(data);
    toggleModal();
  }

  function onError(error) {
    setFeedback({
      type: 'error',
      message: error,
    });
  }

  const renderPaymentInfo = () => {
    if (!payment) {
      return null;
    }

    if (payment.payment_method_id === PAYMENT_METHODS.CASH) {
      return (
        <div className="mt-4 website-text-mini d-flex">
          <CashIcon className="my-auto h-6 w-6" />
          <div className="my-auto ms-2">{t('labels.cash_payment')}</div>
        </div>
      );
    }

    return (
      <div className="mt-4 website-text-mini secondary d-flex">
        <CreditCardIcon width={28} height={20} className="card-icon me-2 sm-mr-1" />
        <div className="mt-auto">{t('labels.online_payment')}</div>
      </div>
    );
  };

  const renderOrderMethodLabel = () => ({
    [ORDER_METHODS.room_service]: t(`order_methods.${order.room_service_location?.type}`),
    [ORDER_METHODS.pickup]: t(`order_methods.${order.method}`),
    [ORDER_METHODS.delivery]: t(`order_methods.${order.method}`),
  })[order.method];

  const renderOrderNumber = () => <span className={classnames({ 'text-danger': order.is_dummy })}>{order.is_dummy ? t('labels.dummy_order') : `#${order.number}`}</span>;

  const renderScheduledDate = () => {
    if (!order.scheduled_for) {
      return null;
    }

    return (
      <span className="tw-mb-tiny tw-text-center">
        {moment(order.scheduled_for).isSame(moment(), 'day')
          ? t('labels.today').capitalize()
          : moment(order.scheduled_for).format('dddd, DD MMM')}
        <br />
      </span>
    );
  };

  const renderOrderTime = () => {
    if (order.delivery?.initial_pickup_estimate) {
      return formatTime(order.delivery?.initial_pickup_estimate);
    }

    return order.scheduled_for
      ? formatTime(order.estimated_pickup_time)
      : t('labels.asap');
  };

  const toggleOptionsSidebar = () => {
    setShowOptionsSidebar(!showOptionsSideBar);
  };

  function renderMoreOptions() {
    return (
      <>
        {showOptionsSideBar && (
          <div
            className="tw-fixed tw-inset-0 tw-bg-black/50"
            onClick={() => setShowOptionsSidebar(false)}
          />
        )}
        <CSidebar
          placement="end"
          visible={showOptionsSideBar}
          onVisibleChange={setShowOptionsSidebar}
          className="tw-z-50 tw-fixed tw-top-0 inset-0 tw-bottom-0"
        >
          <CSidebarNav>
            {isRefundable && (
              <CNavItem>
                <CNavLink
                  href="#"
                  onClick={() => {
                    toggleOptionsSidebar();
                    toggleRefundModal();
                  }}
                >
                  <span>
                    <RefundIcon className="mr-2 my-auto" />
                    {t('buttons.refund')}
                  </span>
                </CNavLink>
              </CNavItem>
            )}
            {isRejectable && (
              <CNavItem>
                <CNavLink
                  href="#"
                  onClick={() => {
                    toggleOptionsSidebar();
                    toggleRejectOrderModal();
                  }}
                >
                  <span className="tw-text-brand-danger">
                    {t('buttons.reject')}
                  </span>
                </CNavLink>
              </CNavItem>
            )}
          </CSidebarNav>
        </CSidebar>
      </>
    );
  }

  return (
    <CModal
      id="order-details-modal"
      visible={show}
      alignment="center"
      scrollable
      size="xl"
      fullscreen
      onClose={() => toggleModal()}
    >
      <CModalHeader closeButton={false}>
        <CModalTitle className="website-text bold">{t('header')}</CModalTitle>
        <CIcon
          icon={cilX}
          size="xxl"
          onClick={() => toggleModal()}
          className="cursor-pointer"
        />
      </CModalHeader>
      <CModalBody className="p-3">
        <div className="tw-flex tw-w-full tw-justify-between tw-gap-4">
          <div>
            <div>
              <h5 className="mr-2">{renderOrderNumber()}</h5>
              <div className="website-text bold">
                {order.customer_first_name}
                {' '}
                {order.customer_last_name}
              </div>
            </div>
            {order.method === ORDER_METHODS.delivery && (
              <>
                {order.delivery_provider === DeliveryProvider.WoltDrive && (
                  <>
                    <div className="website-text mt-1 tw-text-wolt">
                      {t('labels.wolt_support', { phone_number: import.meta.env.VITE_WOLT_SUPPORT_PHONE_NUMBER })}
                    </div>
                    <FeedbackLabel
                      type="warning"
                      message={<Trans t={t}>labels.wolt_support_text</Trans>}
                      className="tw-mt-mini tw-p-mini"
                    />
                  </>
                )}
                <div className="website-text mt-1">
                  {order.delivery_street_name}
                  {' '}
                  {order.delivery_street_number}
                  ,
                </div>
                <div className="website-text mt-1">
                  {order.delivery_zip_code}
                  {' '}
                  {order.delivery_city}
                </div>
                {order.delivery_doorbell_name && <div className="website-text mt-1">{order.delivery_doorbell_name}</div>}
                {order.delivery_company_name && <div className="website-text mt-1">{order.delivery_company_name}</div>}
              </>
            )}
            {order.method === ORDER_METHODS.room_service && (
              <>
                <div className="website-text mt-1">{order.room_service_location.name}</div>
                <div className="website-text mt-1">
                  {order.room_service_location.street_name}
                  {' '}
                  {order.room_service_location.street_number}
                </div>
                {order.room_service_details && (
                  <div className="website-text mt-1">
                    {t(`room_service_location_types.${order.room_service_location.type}.details_label`)}
                    {': '}
                    {order.room_service_details}
                  </div>
                )}
              </>
            )}
            <div className="website-text mt-1">
              <b>Tel:</b>
              {' '}
              {order.customer_phone_number}
            </div>
            {order?.order_instructions?.length > 0 && (
              <div className="website-text mt-1">
                <b>{t('labels.order_instructions')}</b>
                {' '}
                {order?.order_instructions}
              </div>
            )}
            <Button
              type={Types.secondary}
              title={(
                <div className="website-text">
                  <PrinterIcon color="#1C1D28" className="printer-icon tw-mr-mini" />
                  {t('buttons.print')}
                </div>
              )}
              onClick={() => print({ type: RECEIPT_TYPE.ORDER, payload: order })}
              loading={printing}
              className="tw-mt-small"
            />
          </div>
          <div>
            <div className="d-flex tw-justify-center">
              <CButton color="link" onClick={toggleOptionsSidebar}>
                <CIcon size="xl" icon={cilOptions} className="tw-rotate-90 tw-text-black" />
              </CButton>
            </div>
            <div className="tw-flex">
              <OrderMethodIcon order={order} className="tw-mx-auto" />
            </div>
            <div className="website-text-mini secondary w-100 tw-mt-mini text-center">
              {renderOrderMethodLabel()}
            </div>
            <PrimaryOrderBadge order={order} className="tw-mt-mini tw-mx-auto">
              {renderScheduledDate()}
              <span className="tw-block tw-text-center">
                {renderOrderTime()}
              </span>
            </PrimaryOrderBadge>
            <DeliveryTimeDifferenceBadge
              initial={order.delivery?.initial_pickup_estimate}
              actual={order.delivery?.pickup_estimate}
              className="w-full mt-2"
            />
          </div>
        </div>
        {order?.coupon_type === COUPON_TYPES.REWARD && (
          <FeedbackLabel
            message={(
              <div className="d-flex">
                <StarIcon className="mr-2 my-auto" />
                <div className="website-text my-auto">{t('labels.points_used')}</div>
              </div>
            )}
            type="notice"
            className="mt-3"
          />
        )}
        {order.scheduled_for && order.fulfillment_status !== FULFILLMENT_STATUSES.preparing && (
          <Schedule isReminder={order.fulfillment_status === FULFILLMENT_STATUSES.accepted}>
            <h5 className="white text-center">
              {t('schedule.header', {
                date: moment(order.estimated_pickup_time).isSame(moment(), 'day')
                  ? t('labels.today')
                  : moment(order.estimated_pickup_time).format('dddd, DD MMM'),
                time: formatTime(order.estimated_pickup_time),
              })}
            </h5>
            <div className="website-text text-center white break-word">{t('schedule.text')}</div>
          </Schedule>
        )}
        {order?.delivery?.runner_name ? (
          <div>
            <Divider className="website-text bold">{t('sections.driver_info')}</Divider>
            <p className="mb-0 mt-3">
              <CarrierIcon className="mr-2" />
              <b>{t('labels.driver_name')}</b>
              {': '}
              {order?.delivery?.runner_name}
            </p>
            {order?.delivery?.runner_phone_number ? (
              <p className="mb-0 mt-2">
                <PhoneIcon className="mr-2" />
                <b>{t('labels.driver_phone_number')}</b>
                {': '}
                {order?.delivery?.runner_phone_number}
              </p>
            ) : null}
          </div>
        ) : null}
        <Divider className="website-text bold">{t('sections.order_info')}</Divider>
        {order.line_items.map((item, idx) => (
          <OrderLineItem
            lineItem={item}
            isLastItem={idx === order.line_items.length - 1}
            key={idx}
          />
        ))}
        <Divider className="website-text bold">{t('sections.price_info')}</Divider>

        <div className="d-flex justify-content-between website-text mt-3">
          <div>{t('labels.original')}</div>
          <div>{formatCurrency(order.original_price)}</div>
        </div>
        {order.method_fee > 0 ? (
          <div className="d-flex justify-content-between website-text mt-3">
            <div>{t('labels.delivery_fee')}</div>
            <div>{formatCurrency(order.method_fee)}</div>
          </div>
        ) : undefined}
        {order.tip > 0 && (
          <div className="d-flex justify-content-between website-text mt-3">
            <div>{t('labels.tip')}</div>
            <div>{formatCurrency(order.tip)}</div>
          </div>
        )}
        {order.service_fee > 0 && (
          <div className="d-flex justify-content-between website-text mt-3">
            <div>{t('labels.service_fee')}</div>
            <div>{formatCurrency(order.service_fee)}</div>
          </div>
        )}
        {order.payment_method_upcharge_fee > 0 && (
          <div className="d-flex justify-content-between website-text mt-3">
            <div>{t(`labels.payment_method_upcharge_fee.${payment.payment_method_id}`)}</div>
            <div>{formatCurrency(order.payment_method_upcharge_fee)}</div>
          </div>
        )}
        {order.total_discount > 0 && (
          <div className="d-flex justify-content-between website-text mt-3">
            <div>{t('labels.promo_applied')}</div>
            <div>
              -
              {formatCurrency(order.total_discount)}
            </div>
          </div>
        )}
        {order?.tax_behaviour === TAX_BEHAVIOUR.exclusive && order.tax > 0 && (
          <div className="d-flex justify-content-between website-text mt-3">
            <div>{t('labels.tax')}</div>
            <div>{formatCurrency(order.tax)}</div>
          </div>
        )}
        <div className="d-flex justify-content-between website-text bold mt-3">
          <div>{t('labels.total')}</div>
          <div>{formatCurrency(order.total_amount)}</div>
        </div>
        {renderPaymentInfo()}
        {Boolean(payment) && payment.refunds?.length > 0 && (
          <>
            <Divider className="website-text bold">{t('sections.refunds')}</Divider>
            {payment.refunds.map((item) => (
              <div>
                <div
                  key={`refund-${item.id}`}
                  className="d-flex justify-content-between website-text bold mt-3"
                >
                  <div className="bold">{formatDateTime(item?.paid_at)}</div>
                  <div className="bold">{formatCurrency(item.total_amount)}</div>
                </div>
                <div className="website-text-mini mt-2 text-decoration-underline">{t(`refund_reasons.${item.refund_reason}`, item.refund_reason)}</div>
              </div>
            ))}
          </>
        )}
      </CModalBody>
      <Footer
        order={order}
        acceptOrder={acceptOrder}
        onOrderPreparing={onOrderPreparing}
        updatePickupTime={updatePickupTime}
        onError={onError}
        toggleModal={toggleModal}
        printing={printing}
      />
      {renderRejectOrderModal}
      {renderRefundModal}
      {renderMoreOptions()}
    </CModal>
  );
}

const Divider = styled.div`
  ${tw`w-full mt-5 -mx-4 bg-gray-400 py-2 px-4`}
  width: 120%;
`;

const Schedule = styled.div((props) => [
  tw`w-full mt-5 bg-blue py-4 px-8 relative`,
  'width: 120%;',
  'margin-left: -10%;',
  props?.isReminder ? tw`bg-orange` : tw`bg-blue`,
]);
